import React from 'react';
import ReactDOM from 'react-dom';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import './index.css';
import MainApp from './MainApp';
import AppContext from './AppContext';

import * as serviceWorker from './serviceWorker';

// global instance of firebase
const config = {
  apiKey: 'AIzaSyDelR13KOI9QwckOwlyyGhfS7hU8IrYdOc',
  authDomain: 'kronoberg-travel-app-dev.firebaseapp.com',
  databaseURL: 'https://kronoberg-travel-app-dev.firebaseio.com',
  projectId: 'kronoberg-travel-app-dev',
  storageBucket: 'kronoberg-travel-app-dev.appspot.com',
  messagingSenderId: '620282438235',
  appId: '1:620282438235:web:4e4647fac667e258',
};
firebase.initializeApp(config);

ReactDOM.render(
  <AppContext.Provider value={{ db: firebase.firestore() }}>
    <MainApp />
  </AppContext.Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
