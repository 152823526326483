export interface TokenEntry {
  id: string;
  token: string;
}

export const digestToObject = (rawText: string): { error: boolean; result?: TokenEntry } => {
  const segments = rawText.split('_');
  if (segments.length !== 3) return { error: true };

  const [firestoreAutoId, randomUUID, token] = segments;
  if (randomUUID.length !== 36) return { error: true };
  if (token.length < 80) return { error: true };

  if (randomUUID.split('-').length !== 5) return { error: true };

  // WdM5oeqtez5Ncv5wcIm4_d9ec6637-2460-47fa-a31f-5f4b84681bb2_YWJjMzI1NzItYzhiNy00YTBiLWI2NTAtOWJlMGZkNWZhYWQ1YTIzZDMxNDQtODA1NC00NTkxLWIwNDUtZGY1NmEwMTNjZTdl
  return { error: false, result: { id: `${firestoreAutoId}_${randomUUID}`, token } };
};
