import React, { FunctionComponent, Component } from 'react';
import { RouteComponentProps, Router, Redirect, navigate } from '@reach/router';
import './App.css';

// context
import { NotFound } from './components/NotFound';
import { ApplicationInfo } from './components/ApplicationInfo';
import { EmailTokenConsumption } from './components/EmailConsumption';
import { SmsTokenConsumption } from './components/SmsTokens'
import AppStoreLogo from './images/app_store.png';
import PlayStoreLogo from './images/play_store.png';

type Props = { component: FunctionComponent | any; [key: string]: any } & RouteComponentProps;
const Route: FunctionComponent<Props> = ({ component: Component, ...rest }) => <Component {...rest} />;

const storeLogo = {
  width: '300px',
  cursor: 'pointer',
  padding: '0.2em',
};

class MainApp extends Component {
  render() {
    return (
      <div className="MainDiv">
        <nav style={{ paddingTop: '1.5em' }}>
          {/* == MOBILE APPS == */}
          <img src={AppStoreLogo} style={storeLogo} onClick={() => navigate('http://example.com/iOS')} alt="iOS apple-store general button" />
          <img src={PlayStoreLogo} style={storeLogo} onClick={() => navigate('http://example.com/Android')} alt="Android apple-store general button" />
          <br />
          {/*
          <Link to="/consume-email-verification/23_qweqweqwe">verification-links</Link>{' '}
          <Link to="/consume-email-rejection/66_qweqweqwe">REJECTION-links</Link>{' '}
           */}
        </nav>
        <Router style={{ marginBottom: 0 }}>
          <Route component={NotFound} default={true} />

          {/* == STATIC-ROUTES == */}
          <Redirect from="/" to="/app" noThrow />
          <Route component={ApplicationInfo} path="/" />
          <Route component={ApplicationInfo} path="/app" />
          {/* == DYNAMIC-ROUTES | EMAIL == */}
          <Route component={EmailTokenConsumption} linkType={true} path="/consume-email-verification/:rawSegment" />
          <Route component={EmailTokenConsumption} linkType={false} path="/consume-email-rejection/:rawSegment" />
          {/* == DYNAMIC-ROUTES | PHONE-NUMBER == */}
          <Route component={SmsTokenConsumption} path="/smsr/:rawSegment" />
        </Router>
      </div>
    );
  }
}

export default MainApp;
