import React from 'react';
import AppContext from '../AppContext';

const appInfoDivStyle = {
  color: '#FFD300',
  height: '100%',
  width: 'auto',
  padding: '10px',
};

export class ApplicationInfo extends React.Component<any, any> {
  static contextType = AppContext;
  static gdprText: string;

  constructor(props: any) {
    super(props);
    this.state = { gdprText: ApplicationInfo.gdprText };
  }

  async componentDidMount() {
    if (!ApplicationInfo.gdprText) {
      const document = await this.context.db.doc(`/platform/gdprText`).get();
      const { sv: gdprText } = document.data();
      this.setState({ gdprText });
      ApplicationInfo.gdprText = gdprText;
    }
  }

  render() {
    return (
      <div style={appInfoDivStyle}>
        <h1>
          <span style={{ color: '#83B81A' }}>RESGRONT</span>
          <br /> MVP | test{' '}
        </h1>
        <h4 style={{ marginTop: '-1em', color: '#B2D1C2' }}>== GDPR ==</h4>
        <div id="gdprText" dangerouslySetInnerHTML={{ __html: this.state.gdprText }} />
      </div>
    );
  }
}
