import React from 'react';
import AppContext, { IGlobalContext } from '../AppContext';
import { navigate } from '@reach/router';

interface SmsConsimptionProps {
  rawSegment: string;
  smsTokenInput: React.RefObject<HTMLInputElement> | null;
}

const smsTokenRegex = new RegExp('^[a-zA-Z1-9]+$');
export class SmsTokenConsumption extends React.Component<
  SmsConsimptionProps,
  { tokenFromSms: string; textColor: string }
> {
  static contextType = AppContext;
  // reference
  smsTokenInput: HTMLInputElement | React.RefObject<unknown>;

  constructor(props: SmsConsimptionProps) {
    super(props);

    this.smsTokenInput = React.createRef();
    this.state = { tokenFromSms: '', textColor: 'gray' };
  }

  componentDidMount() {
    const { rawSegment } = this.props;
    if (rawSegment.length < 16) {
      setTimeout(() => {
        navigate(`/app`);
      }, 100);
    }
    this.tokenFocus();
  }

  tokenFocus = () => {
    (this.smsTokenInput as HTMLElement).focus();
  };

  tokenInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value;
    // prevent more than 8 characters
    if (text.length > 8) {
      text = text.slice(0, 8);
    }

    if (text.length === 0 || smsTokenRegex.test(text)) {
      const tokenFromSms = text.toUpperCase();
      this.setState({ tokenFromSms, textColor: this.validTokenFormat(tokenFromSms) ? 'black' : 'gray' });
    }
  };

  validTokenFormat(token: string): boolean {
    return token.length === 8 && token === token.toUpperCase();
  }

  rejectClicked = async () => {
    const token = this.state.tokenFromSms;
    if (!this.validTokenFormat(this.state.tokenFromSms)) {
      alert('Please, the token has to be at least of length 8!');
      return this.tokenFocus();
    }
    const { db } = this.context as IGlobalContext;
    // Firestore Reject Entry
    try {
    await db!.doc(`/invocations/consumeSmsToken/rejectionEntries/${this.props.rawSegment}`).update({ token });
    } finally {
      navigate('/app');
    }
  };

  render() {
    return (
      <p id="smsTokenDiv" style={{ margin: '2em' }}>
        <b />
        <input
          ref={(el) => {
            this.smsTokenInput = el!;
          }}
          maxLength={8}
          style={{
            textTransform: 'uppercase',
            fontSize: '3em',
            width: '10ch',
            textAlign: 'center',
            color: this.state.textColor,
          }}
          type="text"
          value={this.state.tokenFromSms}
          onChange={this.tokenInput}
        />
        <br />
        <input
          style={{
            fontSize: '1em',
            color: 'red',
            background: 'transparent',
            border: '1px dashed red',
            cursor: 'pointer',
            margin: '1em',
          }}
          type="button"
          value="CLICK HERE"
          onClick={this.rejectClicked}
        />
        <br />
        <span style={{ color: 'white' }}>
          Write the received TOKEN and submit by pressing the button in order to stop receiving any form of SMS from
          this service!
        </span>
      </p>
    );
  }
}
