import React from 'react';
import { navigate } from '@reach/router';

// interfaces
import AppContext, { IGlobalContext } from '../AppContext';
import { digestToObject, TokenEntry } from '../common/helpers';

// media resources
import ValidLoading from '../images/valid_loader.gif';
import InvalidLoading from '../images/invalid_loader.gif';

interface EmailChangeRequestSegment {
  linkType: boolean;
  rawSegment: string;
}

// FIRESTORE - db entries
const replaceTerm = 'CORRECT_COLLECTION';
const baseConsumePath = `/invocations/consumeEmailToken/${replaceTerm}/`;

const ValidTokenFormat = (props: any) => (
  <div id="validTokenDiv">
    <h3>Token seems to be valid and has been consumed!</h3>
    <hr/>
    <h3>{props.innerText}</h3>
   
    <h5 className="redirectionText"> <img className="redirectionLoader" src={ValidLoading} alt="valid loader gif" /> Redirecting back to the home page . . .</h5>
  </div>
);

const InvalidTokenFormat = () => (
  <div id="invalidTokenDiv">
    <h3>The token is either invalid, expired or already consumed!</h3>
    <h5 className="redirectionText"> <img className="redirectionLoader" src={InvalidLoading} alt="invalid loader gif" /> Redirecting back to the home page . . .</h5>
  </div>
);


export class EmailTokenConsumption extends React.Component<EmailChangeRequestSegment, { compStateError: boolean | null, result: TokenEntry | undefined }> {
  static contextType = AppContext;

  constructor(props: EmailChangeRequestSegment) {
    super(props);
    // =========
    const { rawSegment } = this.props;
    const digestedObject = digestToObject(rawSegment);
    // null is default starting  --> empty component
    this.state = { compStateError: null, result: digestedObject.result };
    if (digestedObject.error) {
      this.state = { compStateError: true, result: undefined };
      console.log(`Error`);
    }
  }

  async componentDidMount() {
    const { db } = this.context as IGlobalContext;
    const correctCollection = this.props.linkType ?  'verificationEntries' : 'rejectionEntries';
    // null or false
    if (this.state.compStateError !== true) {
      try {
        // network request based on the LINK_TYPE
        console.log(`>> DOING FIREBASE document update!!!`);
        const { id: docId, token } = this.state.result!;
        const absCollectionPath = String(baseConsumePath.replace(replaceTerm, correctCollection));
        await db!.collection(absCollectionPath).doc(docId).update({ token });
        // succesful
        this.setState({ compStateError: false });
      } catch(err) {
        console.log(err);
        this.setState({ compStateError: true });
      }
    }

    setTimeout(() => {
      navigate(`/app`);
    }, 3000);
  }
  
  render() {
    const text = this.props.linkType ? 'Your confirmation request has been processed succesfully!' : 'Your rejection request has been processed succesfully!';

    let correctComp;
    if (this.state.compStateError === false) {
      correctComp = <ValidTokenFormat innerText={text} />;
    } else if (this.state.compStateError === true) {
      correctComp = <InvalidTokenFormat />;
    } else {
      // as first - if token is valid, this will show until request is finished!
      correctComp = <></>;
    }
    console.log(`CORRECT COMP: ${this.state.compStateError}`);

    return (
      <div style={{ margin: '1em' }}>
        {correctComp}
      </div>
    );
  }
}
